const selectors = {
    drawer: '[data-drawer="search"]',
    closeButton: '[data-search-close-button]',
    searchInput: '[data-search-input]',
    overlay: '.overlay',
    submitSearchButton: '[data-search-submit-button]',
    submitSearchMobileButton: '[data-search-submit-mobile-button]',
    searchMobileInput: '[data-search-mobile-input]',
    searchMobilePopular: '[data-search-mobile-popular]',
    closeSearchMobileButton: '[data-search-mobile-close-button]'
};

class Search {
    constructor(header) {
        this.drawer = document.querySelector(selectors.drawer);
        this.closeButton = document.querySelector(selectors.closeButton);
        this.searchInput = document.querySelector(selectors.searchInput);
        this.overlay = document.querySelector(selectors.overlay);
        this.submitSearchButton = document.querySelector(selectors.submitSearchButton);
        this.submitSearchMobileButton = document.querySelector(selectors.submitSearchMobileButton);
        this.searchMobileInput = document.querySelector(selectors.searchMobileInput);
        this.searchMobilePopular = document.querySelector(selectors.searchMobilePopular);
        this.closeSearchMobileButton = document.querySelector(selectors.closeSearchMobileButton);

        this.closeButton.addEventListener('click', e => {
            e.preventDefault();
            header.closeDrawer(this.drawer);
            this.submitSearchButton.setAttribute('data-state', 'hidden');
        });

        this.searchInput.addEventListener('click', e => {
            e.preventDefault();
            this.submitSearchButton.setAttribute('data-state', 'visible');
        });

        this.searchMobileInput.addEventListener('focus', e => {
            e.preventDefault();
            this.submitSearchMobileButton.setAttribute('data-state', 'visible');
            this.searchMobilePopular.setAttribute('data-state', 'visible');
            this.closeSearchMobileButton.setAttribute('data-state', 'visible');
            this.overlay.style.setProperty('--overlay-z-index', 30);
            this.overlay.setAttribute('data-state', 'visible');
            document.body.classList.add('has-open-drawer');
        });

        this.closeSearchMobileButton.addEventListener('click', e => {
            e.preventDefault();
            this.submitSearchMobileButton.setAttribute('data-state', 'hidden');
            this.searchMobilePopular.setAttribute('data-state', 'hidden');
            this.closeSearchMobileButton.setAttribute('data-state', 'hidden');
            this.overlay.setAttribute('data-state', 'hidden');
            document.body.classList.remove('has-open-drawer');
        });

        this.overlay.addEventListener('click', e => {
            e.preventDefault();
            this.submitSearchMobileButton.setAttribute('data-state', 'hidden');
            this.searchMobilePopular.setAttribute('data-state', 'hidden');
            this.closeSearchMobileButton.setAttribute('data-state', 'hidden');
            this.overlay.setAttribute('data-state', 'hidden');
            document.body.classList.remove('has-open-drawer');
        });

        window.addEventListener('resize',  e => {
            e.preventDefault();
            header.closeDrawer(this.drawer);
            this.overlay.setAttribute('data-state', 'hidden');
            document.body.classList.remove('has-open-drawer');
        }, true);

    }
}

export default Search;
